import { MenuItem } from '../menu.styles';

const MenuFaq = ({ fill }: { fill: string }) => {
    return (
        <MenuItem
            width="36"
            height="50"
            viewBox="0 0 36 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M24.2402 39.538V48.5054C24.2402 48.9039 24.099 49.2526 23.8167 49.5515C23.5344 49.8505 23.2051 49.9999 22.8287 49.9999H14.3598C13.9834 49.9999 13.654 49.8505 13.3717 49.5515C13.0894 49.2526 12.9483 48.9039 12.9483 48.5054V39.538C12.9483 39.1394 13.0894 38.7907 13.3717 38.4918C13.654 38.1929 13.9834 38.0434 14.3598 38.0434H22.8287C23.2051 38.0434 23.5344 38.1929 23.8167 38.4918C24.099 38.7907 24.2402 39.1394 24.2402 39.538ZM35.3909 17.1195C35.3909 18.4646 35.2086 19.7225 34.844 20.8933C34.4793 22.064 34.0677 23.0168 33.6089 23.7516C33.1502 24.4864 32.5033 25.2275 31.6681 25.9748C30.833 26.7221 30.1567 27.2638 29.6391 27.6001C29.1216 27.9364 28.4041 28.3785 27.4866 28.9265C26.5221 29.4995 25.7164 30.309 25.0694 31.3552C24.4225 32.4014 24.099 33.2359 24.099 33.8586C24.099 34.2821 23.9579 34.6869 23.6756 35.0729C23.3933 35.459 23.0639 35.6521 22.6875 35.6521H14.2186C13.8658 35.6521 13.5658 35.4217 13.3188 34.9609C13.0718 34.5 12.9483 34.033 12.9483 33.5597V31.8783C12.9483 29.8108 13.7128 27.8617 15.242 26.0308C16.7711 24.2 18.4531 22.8486 20.288 21.9768C21.676 21.3043 22.664 20.6068 23.2521 19.8844C23.8403 19.1621 24.1343 18.2155 24.1343 17.0448C24.1343 15.9986 23.5874 15.0769 22.4935 14.2798C21.3996 13.4827 20.1351 13.0842 18.7001 13.0842C17.171 13.0842 15.9006 13.4453 14.8891 14.1677C14.0657 14.7905 12.8071 16.2227 11.1134 18.4646C10.8075 18.8631 10.4429 19.0624 10.0195 19.0624C9.73716 19.0624 9.4431 18.9628 9.13728 18.7635L3.35018 14.093C3.04436 13.8439 2.86204 13.5325 2.80323 13.1589C2.74442 12.7852 2.80911 12.4365 2.99731 12.1127C6.76127 5.48678 12.219 2.17383 19.3706 2.17383C21.2525 2.17383 23.1463 2.55992 25.0518 3.33212C26.9573 4.10431 28.6746 5.13805 30.2037 6.43334C31.7328 7.72863 32.9796 9.31661 33.9441 11.1973C34.9087 13.0779 35.3909 15.052 35.3909 17.1195Z"
                fill={fill}
            />
            <path
                d="M18.3288 20.2546L18.3288 20.2546L18.3322 20.2529C19.7482 19.5668 20.8112 18.8306 21.4661 18.0263C22.1438 17.1938 22.4605 16.1264 22.4605 14.8709C22.4605 13.6194 21.7953 12.5626 20.6141 11.7019C19.4293 10.8386 18.0603 10.4103 16.5263 10.4103C14.9173 10.4103 13.5389 10.7913 12.4247 11.587L12.4246 11.5868L12.4136 11.5951C11.5272 12.2655 10.2307 13.7524 8.54179 15.9878C8.31115 16.2877 8.08337 16.3886 7.84563 16.3886C7.68934 16.3886 7.49546 16.3355 7.25663 16.1838L1.49212 11.5315C1.49185 11.5313 1.49159 11.531 1.49132 11.5308C1.27708 11.3561 1.16204 11.1533 1.12332 10.9073C1.08218 10.6459 1.12592 10.4135 1.25578 10.1901L1.2558 10.1901L1.25823 10.1858C4.94049 3.70374 10.2391 0.5 17.1967 0.5C19.0086 0.5 20.8388 0.871421 22.6902 1.62168C24.5478 2.37447 26.2191 3.38094 27.7067 4.64103C29.1828 5.89142 30.3893 7.42633 31.3254 9.25161C32.2546 11.0633 32.7171 12.9593 32.7171 14.9457C32.7171 16.2452 32.541 17.4526 32.1928 18.5707C31.836 19.7161 31.4398 20.626 31.011 21.313C30.5833 21.998 29.9706 22.7038 29.1609 23.4283C28.3321 24.1699 27.6782 24.6917 27.1929 25.007C26.6831 25.3382 25.9719 25.7766 25.0568 26.3232C24.0168 26.9411 23.1553 27.8107 22.4703 28.9184C21.8139 29.98 21.4252 30.9154 21.4252 31.6848C21.4252 31.9886 21.3263 32.292 21.0981 32.604C20.8817 32.9 20.6881 32.9783 20.5137 32.9783H12.0448C11.9572 32.9783 11.7921 32.9359 11.5857 32.5508C11.3734 32.1549 11.2745 31.7684 11.2745 31.3859V29.7045C11.2745 27.7719 11.9857 25.933 13.4519 24.1775C14.9427 22.3925 16.5694 21.0905 18.3288 20.2546ZM21.5664 37.3641V46.3315C21.5664 46.598 21.4771 46.8251 21.2794 47.0344C21.0819 47.2436 20.8802 47.3261 20.6549 47.3261H12.1859C11.9606 47.3261 11.7589 47.2436 11.5614 47.0344C11.3637 46.8251 11.2745 46.598 11.2745 46.3315V37.3641C11.2745 37.0976 11.3637 36.8706 11.5614 36.6612C11.7589 36.4521 11.9606 36.3696 12.1859 36.3696H20.6549C20.8802 36.3696 21.0819 36.4521 21.2794 36.6612C21.4771 36.8706 21.5664 37.0976 21.5664 37.3641Z"
                stroke="black"
            />
        </MenuItem>
    );
};

export default MenuFaq;
