import { createSelector } from 'reselect';

import { RootState } from '../store';

import { SuccessState } from './success.reducer';

export const selectSuccessReducer = (state: RootState): SuccessState => state.success;

export const selectIsSuccess = createSelector(
    selectSuccessReducer,
    (success) => success.isSuccess
);

export const selectSuccessMessage = createSelector(
    selectSuccessReducer,
    (success) => success.successMessage
);





