import { Action as AnyAction } from '@reduxjs/toolkit';
import { getPollutionDataFailed, getPollutionDataSuccess } from './pollution.action';

export type Pollutant = {
    label: string;
    value?: number;
    certificato?: string;
    thresholds: [number, number, string, string][];
};

export type PollutantWithKey = Pollutant & {
    key: string;
};

export type PollutionPoint = {
    lat: number;
    lon: number;
    sostanza: {
        [key: string]: Pollutant;
    };
};

export type PollutionPointWithKey = PollutionPoint & {
    key: string;
};


export type PollutionData = {
    [key: string]: PollutionPoint;
};

export type PollutionState = {
    readonly pollutionData: PollutionData;
    readonly isLoading: boolean;
    readonly error: string | null;
};

const INITIAL_STATE: PollutionState = {
    pollutionData: {},
    isLoading: false,
    error: null,
};

export const pollutionReducer = (state = INITIAL_STATE, action: AnyAction) => {
    if (getPollutionDataSuccess.match(action)) {
        return { ...state, ...action.payload, error: null };
    }

    if (
        getPollutionDataFailed.match(action)
    ) {
        return { ...state, error: action.payload };
    }

    return state;
};