import { ReactComponent as MainLogo } from 'src/assets/main-logo.svg';
import { ReactComponent as UserLogo } from 'src/assets/user-logo.svg';

import styled from 'styled-components';

export const HeaderOuterBox = styled.div`
    width: 100%;
    padding: 1rem 1rem;
`;

export const HeaderContainer = styled.header`
    display: flex;
    width: 100%;
    height: 15vw;
    justify-content: space-between;
    align-items: center;
    @media (orientation: landscape) {
        height: 15vh;
    }
`;

export const MainLogoSvg = styled(MainLogo)`
    width: auto;
    height: 100%;
    display: block;
`;

export const UserLogoSvg = styled(UserLogo)`
    width: auto;
    height: 100%;
    display: block;
`;
