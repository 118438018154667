import styled from 'styled-components';

export const SuccessSnackbarContainer = styled('div')<{
    show: boolean;
}>`
    position: fixed;
    z-index: ${(props) => (props.show ? 1000 : -1)};
    bottom: 1rem;
    left: 1rem;
    right: 1rem;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 2rem);
    height: auto;
    padding: 0.625rem 1rem;
    border-radius: 0.75rem;
    border: transparent;
    background-color: ${(props) => props.theme.colors.success.main};
    color: white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: ${(props) => (props.show ? 1 : 0)};
    transition: all 0.5s ease-in-out;
`;

export const CustomSnackbarButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.875rem;
    padding: 0;
    margin-left: 1rem;
    height: 1.75rem;
    width: 1.75rem;
    text-align: center;
    border: none;
    border-radius: 50%;
    background-color: transparent;
    color: white;
`;
