import { POLLUTION_DATA_URL } from 'src/constants/api.constants';
import { apiRequestWrapper } from 'src/utils/api/api.utils';
import { all, call, put, takeLatest } from 'typed-redux-saga/macro';
import { setIsError } from '../error/error.action';
import { setIsLoading } from '../loading/loading.action';
import { getPollutionDataFailed, getPollutionDataSuccess } from './pollution.action';
import { POLLUTION_ACTION_TYPES } from './pollution.types';


export function* getPollutionData() {
    yield* put(setIsLoading(true));
    try {
        const response = yield* call(
            apiRequestWrapper, POLLUTION_DATA_URL, 'GET', null
        );

        if (response) {
            yield* put(getPollutionDataSuccess(response));
        }
        yield* put(setIsLoading(false));
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(getPollutionDataFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* onGetPollutionDataStart() {
    yield* takeLatest(POLLUTION_ACTION_TYPES.GET_POLLUTION_DATA_START, getPollutionData);
}

export function* pollutionSaga() {
    yield* all([
        call(onGetPollutionDataStart),
    ]);
}
