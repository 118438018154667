import styled from 'styled-components';

export const PopoverContainer = styled.div`
    display: flex;
    max-width: 331px;
    flex-direction: column;
`;

export const PopoverMenu = styled.div`
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background-color: #f7f7ec;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    font-size: 15px;
    color: #000;
    font-weight: 400;
    text-align: center;
    padding: 0 1rem;
`;

export const PopoverMenuItem = styled.div`
    padding: 0.5rem 1rem;
    width: 100%;
    border-bottom: ${(props) =>
        `0.5px solid ${props.theme.colors.primary.darker}`};
    color: ${(props) => props.theme.colors.primary.darker};
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    &:last-child {
        border-bottom: none;
    }
`;
