import { Action as AnyAction } from '@reduxjs/toolkit';
import { setIsLoading } from './loading.action';

export type LoadingState = {
    readonly isLoading: boolean;
};

const INITIAL_STATE: LoadingState = {
    isLoading: false,
};

export const loadingReducer = (state = INITIAL_STATE, action: AnyAction) => {
    if (
        setIsLoading.match(action)) {
        return { ...state, ...action.payload };
    }

    return state;
};