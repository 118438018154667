import styled from 'styled-components';

export const MenuOuterBox = styled.div`
    width: calc(100vw + 14px);
    border-radius: 50px 50px 0px 0px;
    border-color: ${(props) => props.theme.colors.secondary.main};
    border-style: solid;
    border-width: 7px 7px 0px 7px;
    background-color: ${(props) => props.theme.colors.tertiary.main};
    padding: 1rem 2rem 1rem 2rem;
    height: 10vh;
    @media (orientation: landscape) {
        height: 10vw;
    }
`;

export const MenuInnerBox = styled.div`
    display: flex;
    justify-content: space-between;
    height: 100%;
`;

export const MenuItem = styled.svg`
    width: 100%;
    height: 100%;
`;
