import moment, { now } from 'moment';
import { SICILIAN_PROVINCES } from 'src/constants/enums.constants';
import { User } from 'src/store/auth/auth.reducer';
import { PollutantWithKey, PollutionData } from 'src/store/pollution/pollution.reducer';

const compare = (a: PollutantWithKey, b: PollutantWithKey) => {
    if (a.key < b.key) {
        return -1;
    }
    if (a.key > b.key) {
        return 1;
    }
    return 0;
};

const uniqueArray = (originalArray: PollutantWithKey[]) => originalArray.filter((obj, index, self) => index ===
    self.findIndex((o) => o.key === obj.key)
);

export const getPollutantFromKey = (key: string, pollutionData: PollutionData) => {
    return getDistinctPollutants(pollutionData).find((item) => item.key === key);
};


export const getDistinctPollutants = (pollutionData: PollutionData) => {
    const pollutantsArray = Object.keys(pollutionData).reduce((acc: PollutantWithKey[], location) => {
        return acc.concat(Object.keys(pollutionData[location].sostanza).map((pollutant) => ({
            key: pollutant,
            ...pollutionData[location].sostanza[pollutant],
        })));
    }, []);
    return uniqueArray(pollutantsArray).sort(compare);
};

export const calculatePollutionPointColor = (pollutionData: PollutionData, pollutantKey: string, location: string) => {
    const pollutant = pollutionData[location].sostanza[pollutantKey];
    const value = pollutant.value;
    const certificato = pollutant.certificato;
    const thresholds = pollutant.thresholds;
    let color = '#AAAAAA';
    thresholds.forEach((threshold) => {
        if (value &&
            value >= threshold[0] &&
            value <= threshold[1] &&
            certificato &&
            moment(certificato).isAfter(moment(now()).add(-7, 'days'))
        ) {
            color = threshold[2];
            return;
        }
    });
    return color;
};

export const calculatePollutionPointInfoText = (pollutionData: PollutionData, pollutantKey: string, location: string) => {
    const pollutant = pollutionData[location].sostanza[pollutantKey];
    if (!pollutant) {
        return 'informazioni non disponibili';
    }
    const value = pollutant.value;
    const certificato = pollutant.certificato;
    const thresholds = pollutant.thresholds;
    let text = 'informazioni non disponibili';
    thresholds.forEach((threshold) => {
        if (value &&
            value >= threshold[0] &&
            value <= threshold[1] &&
            certificato &&
            moment(certificato).isAfter(moment(now()).add(-7, 'days'))) {
            text = threshold[3];
            return;
        }
    });
    return text;
};

export const isPollutionMenuItemVisible = (user: User | null) => {
    if (!user) {
        return false;
    }
    return SICILIAN_PROVINCES.includes(user.userprofile.province_domicile);
};