import {
    ActionWithPayload, createAction, withMatcher,
} from '../../utils/reducer/reducer.utils';
import { LOADING_ACTION_TYPES } from './loading.types';

export type SetIsLoading = ActionWithPayload<
    LOADING_ACTION_TYPES.SET_IS_LOADING,
    { isLoading: boolean; }
>;

export const setIsLoading = withMatcher(
    (isLoading: boolean): SetIsLoading =>
        createAction(LOADING_ACTION_TYPES.SET_IS_LOADING, { isLoading })
);