
import { POLLUTION_ACTION_TYPES } from './pollution.types';

import {
    Action,
    ActionWithPayload,
    createAction,
    withMatcher
} from '../../utils/reducer/reducer.utils';
import { PollutionData } from './pollution.reducer';

export type GetPollutionDataStart = Action<
    POLLUTION_ACTION_TYPES.GET_POLLUTION_DATA_START
>;

export type GetPollutionDataSuccess = ActionWithPayload<
    POLLUTION_ACTION_TYPES.GET_POLLUTION_DATA_SUCCESS,
    { pollutionData: PollutionData; }
>;

export type GetPollutionDataFailed = ActionWithPayload<
    POLLUTION_ACTION_TYPES.GET_POLLUTION_DATA_FAILED,
    string
>;

export const getPollutionDataStart = withMatcher(
    (): GetPollutionDataStart =>
        createAction(POLLUTION_ACTION_TYPES.GET_POLLUTION_DATA_START)
);

export const getPollutionDataSuccess = withMatcher(
    (pollutionData: PollutionData): GetPollutionDataSuccess =>
        createAction(POLLUTION_ACTION_TYPES.GET_POLLUTION_DATA_SUCCESS, { pollutionData })
);

export const getPollutionDataFailed = withMatcher(
    (error: string): GetPollutionDataFailed =>
        createAction(POLLUTION_ACTION_TYPES.GET_POLLUTION_DATA_FAILED, error)
);


