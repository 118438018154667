import { all, call, put, takeLatest } from 'typed-redux-saga/macro';
import { FAQ_URL } from '../../constants/api.constants';
import { apiRequestWrapper } from '../../utils/api/api.utils';
import { setIsError } from '../error/error.action';
import { setIsLoading } from '../loading/loading.action';
import { AddFAQStart, addFAQFailed, addFAQSuccess, getFAQFailed, getFAQSuccess } from './faq.action';
import { FAQ_ACTION_TYPES } from './faq.types';


export function* getFaq() {
    yield* put(setIsLoading(true));
    try {
        const faqsResponse = yield* call(
            apiRequestWrapper, FAQ_URL, 'GET', null
        );

        if (faqsResponse) {
            yield* put(getFAQSuccess(faqsResponse.results));
        }
        yield* put(setIsLoading(false));
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(getFAQFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* addFaq(addFaqAction: AddFAQStart) {
    yield* put(setIsLoading(true));
    try {

        const request = {
            question: addFaqAction.payload.question,
        };

        const response = yield* call(
            apiRequestWrapper, FAQ_URL, 'POST', request, true
        );

        if (response) {
            yield* put(addFAQSuccess(response.results));
            addFaqAction.payload.onComplete(response.id);
        }
        yield* put(setIsLoading(false));
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(addFAQFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* onGetFAQStart() {
    yield* takeLatest(FAQ_ACTION_TYPES.GET_FAQ_START, getFaq);
}

export function* onAddFAQStart() {
    yield* takeLatest(FAQ_ACTION_TYPES.ADD_FAQ_START, addFaq);
}

export function* faqSaga() {
    yield* all([
        call(onGetFAQStart),
        call(onAddFAQStart),
    ]);
}
