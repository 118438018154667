import { PayloadAction } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { authReducer } from './auth/auth.reducer';
import { AUTH_ACTION_TYPES } from './auth/auth.types';
import { errorReducer } from './error/error.reducer';
import { faqReducer } from './faq/faq.reducer';
import { loadingReducer } from './loading/loading.reducer';
import { newsReducer } from './news/news.reducer';
import { pollutionReducer } from './pollution/pollution.reducer';
import { settingsReducer } from './settings/settings.reducer';
import { successReducer } from './success/success.reducer';

const appReducer = combineReducers({
    loading: loadingReducer,
    error: errorReducer,
    success: successReducer,
    auth: authReducer,
    settings: settingsReducer,
    news: newsReducer,
    pollution: pollutionReducer,
    faq: faqReducer,
});

export const rootReducer = (state: any, action: PayloadAction) => {
    if (action.type === AUTH_ACTION_TYPES.SIGN_OUT) {
        state = undefined;
    }


    return appReducer(state, action);
};
