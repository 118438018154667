import { Action as AnyAction } from '@reduxjs/toolkit';
import { setIsSuccess } from './success.action';

export type SuccessState = {
    readonly isSuccess: boolean;
    readonly successMessage: string | null;
};

const INITIAL_STATE: SuccessState = {
    isSuccess: false,
    successMessage: null,
};

export const successReducer = (state = INITIAL_STATE, action: AnyAction) => {
    if (
        setIsSuccess.match(action)) {
        return { ...state, ...action.payload };
    }

    return state;
};