export enum AUTH_ACTION_TYPES {
    SIGN_IN_START = 'auth/SIGN_IN_START',
    SIGN_IN_SUCCESS = 'auth/SIGN_IN_SUCCESS',
    SIGN_IN_FAILED = 'auth/SIGN_IN_FAILED',
    GET_USER_PROFILE_START = 'auth/GET_USER_PROFILE_START',
    GET_USER_PROFILE_SUCCESS = 'auth/GET_USER_PROFILE_SUCCESS',
    GET_USER_PROFILE_FAILED = 'auth/GET_USER_PROFILE_FAILED',
    REFRESH_USER_PROFILE = 'auth/REFRESH_USER_PROFILE',
    GET_RANDOM_USERNAME_START = 'auth/GET_RANDOM_USERNAME_START',
    GET_RANDOM_USERNAME_SUCCESS = 'auth/GET_RANDOM_USERNAME_SUCCESS',
    GET_RANDOM_USERNAME_FAILED = 'auth/GET_RANDOM_USERNAME_FAILED',
    SET_FIRST_SURVEY_COMPLETED_START = 'auth/SET_FIRST_SURVEY_COMPLETED_START',
    SET_FIRST_SURVEY_COMPLETED_SUCCESS = 'auth/SET_FIRST_SURVEY_COMPLETED_SUCCESS',
    SET_FIRST_SURVEY_COMPLETED_FAILED = 'auth/SET_FIRST_SURVEY_COMPLETED_FAILED',
    SET_LAST_SURVEY_COMPLETED_START = 'auth/SET_LAST_SURVEY_COMPLETED_START',
    SET_LAST_SURVEY_COMPLETED_SUCCESS = 'auth/SET_LAST_SURVEY_COMPLETED_SUCCESS',
    SET_LAST_SURVEY_COMPLETED_FAILED = 'auth/SET_LAST_SURVEY_COMPLETED_FAILED',
    SIGN_UP_START = 'auth/SIGN_UP_START',
    SIGN_UP_SUCCESS = 'auth/SIGN_UP_SUCCESS',
    SIGN_UP_FAILED = 'auth/SIGN_UP_FAILED',
    SIGN_OUT = 'auth/SIGN_OUT',
};