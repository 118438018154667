export enum NEWS_ACTION_TYPES {
    GET_NEWS_START = 'news/GET_NEWS_START',
    GET_NEWS_SUCCESS = 'news/GET_NEWS_SUCCESS',
    GET_NEWS_FAILED = 'news/GET_NEWS_FAILED',
    GET_NEWS_CATEGORIES_START = 'news/GET_NEWS_CATEGORIES_START',
    GET_NEWS_CATEGORIES_SUCCESS = 'news/GET_NEWS_CATEGORIES_SUCCESS',
    GET_NEWS_CATEGORIES_FAILED = 'news/GET_NEWS_CATEGORIES_FAILED',
    TOGGLE_NEWS_FAVORITE_START = 'news/TOGGLE_NEWS_FAVORITE_START',
    TOGGLE_NEWS_FAVORITE_SUCCESS = 'news/TOGGLE_NEWS_FAVORITE_SUCCESS',
    TOGGLE_NEWS_FAVORITE_FAILED = 'news/TOGGLE_NEWS_FAVORITE_FAILED',
};