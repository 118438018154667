import { all, call, put, takeLatest } from 'typed-redux-saga/macro';
import { SETTINGS_URL } from '../../constants/api.constants';
import { apiRequestWrapper } from '../../utils/api/api.utils';
import { setIsError } from '../error/error.action';
import { setIsLoading } from '../loading/loading.action';
import { getSettingsFailed, getSettingsSuccess } from './settings.action';
import { SETTINGS_ACTION_TYPES } from './settings.types';


export function* getSettings() {
    yield* put(setIsLoading(true));
    try {
        const settingsResponse = yield* call(
            apiRequestWrapper, SETTINGS_URL, 'GET', null
        );

        if (settingsResponse) {
            yield* put(getSettingsSuccess(settingsResponse.results));
        }
        yield* put(setIsLoading(false));
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(getSettingsFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* onGetSettingsStart() {
    yield* takeLatest(SETTINGS_ACTION_TYPES.GET_SETTINGS_START, getSettings);
}

export function* settingsSaga() {
    yield* all([
        call(onGetSettingsStart),
    ]);
}
