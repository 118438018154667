import axios from 'axios';
import { BACKEND_CLIENT_ID, BACKEND_GRANT_TYPE, LOGIN_URL, USER_PROFILE_URL } from '../../constants/api.constants';

export const signInWithEmailAndPassword = async (email: string, password: string, registration_id: string) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };
    const payload = {
        client_id: BACKEND_CLIENT_ID,
        grant_type: BACKEND_GRANT_TYPE,
        username: email,
        password: password,
        registration_id: registration_id
    };

    try {

        const { data } = await axios.post(
            LOGIN_URL,
            payload,
            config
        );

        return data['access_token'];

    } catch (err) {

        throw err;

    }
};


export const getUserProfileAfterSignIn = async (accessToken: string) => {
    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    };

    try {

        const { data } = await axios.get(
            USER_PROFILE_URL,
            config
        );

        return data;

    } catch (err) {

        throw err;

    }
};