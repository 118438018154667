import {
    ActionWithPayload, createAction, withMatcher,
} from '../../utils/reducer/reducer.utils';
import { ERROR_ACTION_TYPES } from './error.types';

export type SetIsError = ActionWithPayload<
    ERROR_ACTION_TYPES.SET_IS_ERROR,
    { isError: boolean; errorMessage: string | null; }
>;

export const setIsError = withMatcher(
    (isError: boolean, errorMessage: string | null): SetIsError =>
        createAction(ERROR_ACTION_TYPES.SET_IS_ERROR, { isError, errorMessage })
);