import { Action as AnyAction } from '@reduxjs/toolkit';
import { setIsError } from './error.action';

export type ErrorState = {
    readonly isError: boolean;
    readonly errorMessage: string | null;
};

const INITIAL_STATE: ErrorState = {
    isError: false,
    errorMessage: null,
};

export const errorReducer = (state = INITIAL_STATE, action: AnyAction) => {
    if (
        setIsError.match(action)) {
        return { ...state, ...action.payload };
    }

    return state;
};