/*firebase daniel start*/
import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: 'AIzaSyDl1Xbt1gE_D7N-7-hhR8eSr8WP2CQNmJE',
    authDomain: 'primi-mille-giorni-a4b82.firebaseapp.com',
    projectId: 'primi-mille-giorni-a4b82',
    storageBucket: 'primi-mille-giorni-a4b82.appspot.com',
    messagingSenderId: '767223845077',
    appId: '1:767223845077:web:9aae1c0717db255f3e525c',
};
export const fapp = initializeApp(firebaseConfig);
export const messaging = getMessaging(fapp);

onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);
});

navigator.serviceWorker.onmessage = (event) => {
    const data = event.data;
    console.log('Data received from service worker:', data);

    if (data.urlToRedirect) {
        window.location.href = data.urlToRedirect;
    }
};
