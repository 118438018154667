import { NEWS_CATEGORIES_URL, NEWS_URL, TOGGLE_NEWS_FAVORITES_URL } from 'src/constants/api.constants';
import { apiRequestWrapper } from 'src/utils/api/api.utils';
import { all, call, put, takeLatest } from 'typed-redux-saga/macro';
import { getUserProfile } from '../auth/auth.saga';
import { setIsError } from '../error/error.action';
import { setIsLoading } from '../loading/loading.action';
import { GetNewsStart, ToggleNewsFavoriteStart, getNewsCategoriesFailed, getNewsCategoriesSuccess, getNewsFailed, getNewsSuccess, toggleNewsFavoriteFailed, toggleNewsFavoriteSuccess } from './news.action';
import { NEWS_ACTION_TYPES } from './news.types';

export function* getNews({
    payload: { searchText, categories, favorite }
}: GetNewsStart) {
    yield* put(setIsLoading(true));
    try {
        let url = `${NEWS_URL}?search=${searchText}&favorite=${favorite}&categories=${categories.join(',')}`;

        const response = yield* call(
            apiRequestWrapper, url, 'GET', null
        );


        if (response) {
            yield* put(getNewsSuccess(response.results));
        }
        yield* put(setIsLoading(false));
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(getNewsFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* getNewsCategories() {
    yield* put(setIsLoading(true));
    try {

        const response = yield* call(
            apiRequestWrapper, `${NEWS_CATEGORIES_URL}`, 'GET', null
        );


        if (response) {
            yield* put(getNewsCategoriesSuccess(response.results));
        }
        yield* put(setIsLoading(false));
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(getNewsCategoriesFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* toggleNewsFavorite(toggleNewsFavoriteAction: ToggleNewsFavoriteStart) {
    yield* put(setIsLoading(true));
    try {
        const request = {
            news_id: toggleNewsFavoriteAction.payload.newsId,
        };

        const response = yield* call(
            apiRequestWrapper, TOGGLE_NEWS_FAVORITES_URL, 'PUT', request, true
        );

        if (response) {
            yield* put(toggleNewsFavoriteSuccess(response.access_token));
        }
        yield* put(setIsLoading(false));
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(toggleNewsFavoriteFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* onGetNewsStart() {
    yield* takeLatest(NEWS_ACTION_TYPES.GET_NEWS_START, getNews);
}

export function* onGetNewsCategoriesStart() {
    yield* takeLatest(NEWS_ACTION_TYPES.GET_NEWS_CATEGORIES_START, getNewsCategories);
}

export function* onToggleNewsFavoriteStart() {
    yield* takeLatest(NEWS_ACTION_TYPES.TOGGLE_NEWS_FAVORITE_START, toggleNewsFavorite);
}

export function* onToggleNewsFavoriteSuccess() {
    yield* takeLatest(NEWS_ACTION_TYPES.TOGGLE_NEWS_FAVORITE_SUCCESS, getUserProfile);
}

export function* newsSaga() {
    yield* all([
        call(onGetNewsStart),
        call(onGetNewsCategoriesStart),
        call(onToggleNewsFavoriteStart),
        call(onToggleNewsFavoriteSuccess)
    ]);
}
