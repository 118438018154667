interface Theme {
    colors: {
        primary: {
            main: string;
            lighter: string;
            lightest: string;
            darker: string;
        };
        secondary: {
            main: string;
            lighter: string;
            lightest: string;
            darker: string;
        };
        tertiary: {
            main: string;
            lighter: string;
            darker: string;
        };
        error: {
            main: string;
            lighter: string;
            darker: string;
        };
        success: {
            main: string;
            lighter: string;
            darker: string;
        };
        grey: {
            main: string;
            lighter: string;
            darker: string;
        };
    };
    text: {
        title: {
            fontSize: string;
            fontStyle: string;
            fontWeight: number;
            lineHeight: string;
        };
    };
}

declare module 'styled-components' {
    export interface DefaultTheme extends Theme { }
}

export const THEME: Theme = {
    colors: {
        primary: {
            main: 'rgb(146,210,255)',
            lighter: 'rgb(185, 233, 246)',
            lightest: 'rgba(228, 244, 247)',
            darker: 'rgb(59,135,168)',
        },
        secondary: {
            main: 'rgb(239, 135, 159)',
            lighter: 'rgb(242, 187, 194)',
            lightest: 'rgb(250, 217, 224)',
            darker: 'rgb(215, 46, 93)',
        },
        tertiary: {
            main: 'rgb(247, 247, 236)',
            lighter: 'rgb(247, 247, 236)',
            darker: 'rgb(247, 247, 236)',
        },
        error: {
            main: 'rgb(234, 0, 57)',
            lighter: 'rgb(255, 0, 0)',
            darker: 'rgb(255, 0, 0)',
        },
        success: {
            main: 'rgb(82, 204, 0)',
            lighter: 'rgb(82, 204, 0)',
            darker: 'rgb(82, 204, 0)',
        },
        grey: {
            main: 'rgb(136, 136, 136)',
            lighter: 'rgb(236, 236, 236)',
            darker: 'rgb(83, 83, 83)',
        },
    },
    text: {
        title: {
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 800,
            lineHeight: 'normal',
        }
    }
};