import { Outlet, useLocation } from 'react-router-dom';
import { LOGIN_ROUTE, SIGN_UP_ROUTE } from 'src/constants/routes.constants';
import CustomSnackbar from '../ui/custom-snackbar/custom-snackbar.component';
import SuccessSnackbar from '../ui/success-snackbar/success-snackbar.component';
import Header from './header/header.component';
import { PageContainer, PageContent } from './layout.styles';
import Menu from './menu/menu.component';

const Layout = () => {
    //const isLoading = useSelector(selectIsLoading);

    const { pathname } = useLocation();

    const isLoginPage = pathname === `/${LOGIN_ROUTE}`;
    const isSignUpPage = pathname === `/${SIGN_UP_ROUTE}`;

    return (
        <PageContainer>
            <CustomSnackbar />
            <SuccessSnackbar />
            {/* {isLoading && <CustomLoadingSpinner />} */}
            {!isLoginPage && !isSignUpPage && <Header />}
            <PageContent>
                <Outlet />
            </PageContent>
            {!isLoginPage && !isSignUpPage && <Menu />}
        </PageContainer>
    );
};

export default Layout;
