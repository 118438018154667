import { createSelector } from 'reselect';

import { RootState } from '../store';

import { ErrorState } from './error.reducer';

export const selectErrorReducer = (state: RootState): ErrorState => state.error;

export const selectIsError = createSelector(
    selectErrorReducer,
    (error) => error.isError
);

export const selectErrorMessage = createSelector(
    selectErrorReducer,
    (error) => error.errorMessage
);





