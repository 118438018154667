import { all, call, put, select, takeLatest } from 'typed-redux-saga/macro';

import { AUTH_ACTION_TYPES } from './auth.types';

import { FIST_SURVEY_COMPLETED_URL, LAST_SURVEY_COMPLETED_URL, RANDOM_USERNAME_URL, SIGN_UP_URL } from 'src/constants/api.constants';
import { apiRequestWrapper } from 'src/utils/api/api.utils';
import { getUserProfileAfterSignIn, signInWithEmailAndPassword } from '../../utils/api/auth.utils';
import { setIsError } from '../error/error.action';
import { setIsLoading } from '../loading/loading.action';
import {
    SignInStart,
    SignInSuccess,
    SignUpStart,
    getRandomUsernameFailed,
    getRandomUsernameSuccess,
    getUserProfileFailed,
    getUserProfileStart,
    getUserProfileSuccess,
    setFirstSurveyCompletedFailed,
    setFirstSurveyCompletedSuccess,
    setLastSurveyCompletedFailed,
    setLastSurveyCompletedSuccess,
    signInFailed,
    signInSuccess,
    signUpFailed,
    signUpSuccess
} from './auth.action';
import { selectAccessToken, selectUserProfile } from './auth.selector';

export function* signIn({
    payload: { email, password, registration_id, onComplete },
}: SignInStart) {
    yield* put(setIsLoading(true));
    try {
        const accessToken = yield* call(
            signInWithEmailAndPassword,
            email,
            password,
            registration_id
        );

        if (accessToken) {
            yield* put(signInSuccess(accessToken));
        }
        yield* put(setIsLoading(false));
        onComplete();
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(signInFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* getUserProfile({
    payload: { accessToken },
}: SignInSuccess) {
    yield* put(setIsLoading(true));
    try {

        yield* put(getUserProfileStart(accessToken));

        const userProfile = yield* call(
            getUserProfileAfterSignIn, accessToken
        );

        if (userProfile) {
            yield* put(getUserProfileSuccess(accessToken, userProfile));
        }
        yield* put(setIsLoading(false));
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(getUserProfileFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* getRandomUsername() {
    yield* put(setIsLoading(true));
    try {

        const username = yield* call(
            apiRequestWrapper, `${RANDOM_USERNAME_URL}`, 'GET', null
        );


        if (username) {
            yield* put(getRandomUsernameSuccess(username));
        }
        yield* put(setIsLoading(false));
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(getRandomUsernameFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* signUp(action: SignUpStart) {
    yield* put(setIsLoading(true));
    try {

        const pregnancyStage = yield* call(
            apiRequestWrapper, `${SIGN_UP_URL}`, 'POST', action.payload
        );


        if (pregnancyStage) {
            yield* put(signUpSuccess());
        }
        yield* put(setIsLoading(false));
        action.payload.onComplete(pregnancyStage);
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(signUpFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* isUserAuthenticated() {

    const accessToken = yield* select(selectAccessToken);
    const userProfile = yield* select(selectUserProfile);
    if (!accessToken || !userProfile) {
        yield* put(signInFailed('Check autenticazione fallito'));
        return;
    }
    yield* put(getUserProfileSuccess(accessToken, userProfile));

}

export function* setFirstSurveyCompleted() {
    yield* put(setIsLoading(true));
    try {


        const response = yield* call(
            apiRequestWrapper, FIST_SURVEY_COMPLETED_URL, 'PUT', null, false
        );

        if (response) {
            yield* put(setFirstSurveyCompletedSuccess(response.access_token));
        }
        yield* put(setIsLoading(false));
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(setFirstSurveyCompletedFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* setLastSurveyCompleted() {
    yield* put(setIsLoading(true));
    try {


        const response = yield* call(
            apiRequestWrapper, LAST_SURVEY_COMPLETED_URL, 'PUT', null, false
        );

        if (response) {
            yield* put(setLastSurveyCompletedSuccess(response.access_token));
        }
        yield* put(setIsLoading(false));
    } catch (error: any) {
        const errorResponse = error.response?.data?.error_description ??
            error.response?.data?.error ?? error.message ?? 'Errore server';
        yield* put(setLastSurveyCompletedFailed(errorResponse));
        yield* put(setIsLoading(false));
        yield* put(setIsError(true, errorResponse));
    }
}

export function* onSignInStart() {
    yield* takeLatest(AUTH_ACTION_TYPES.SIGN_IN_START, signIn);
}

export function* onSignInSuccess() {
    yield* takeLatest(AUTH_ACTION_TYPES.SIGN_IN_SUCCESS, getUserProfile);
}

export function* onGetRandomUsername() {
    yield* takeLatest(AUTH_ACTION_TYPES.GET_RANDOM_USERNAME_START, getRandomUsername);
}

export function* onSignUpStart() {
    yield* takeLatest(AUTH_ACTION_TYPES.SIGN_UP_START, signUp);
}

export function* onSignUpFailed() {
    yield* takeLatest(AUTH_ACTION_TYPES.SIGN_UP_FAILED, getRandomUsername);
}

export function* onSetFirstSurveyCompletedStart() {
    yield* takeLatest(AUTH_ACTION_TYPES.SET_FIRST_SURVEY_COMPLETED_START, setFirstSurveyCompleted);
}

export function* onSetFirstSurveyCompletedSuccess() {
    yield* takeLatest(AUTH_ACTION_TYPES.SET_FIRST_SURVEY_COMPLETED_SUCCESS, getUserProfile);
}

export function* onSetLastSurveyCompletedStart() {
    yield* takeLatest(AUTH_ACTION_TYPES.SET_LAST_SURVEY_COMPLETED_START, setLastSurveyCompleted);
}

export function* onSetLastSurveyCompletedSuccess() {
    yield* takeLatest(AUTH_ACTION_TYPES.SET_LAST_SURVEY_COMPLETED_SUCCESS, getUserProfile);
}

export function* onRefreshUserProfile() {
    yield* takeLatest(AUTH_ACTION_TYPES.REFRESH_USER_PROFILE, getUserProfile);
}

export function* authSaga() {
    yield* all([
        call(onSignInStart),
        call(onSignInSuccess),
        call(onGetRandomUsername),
        call(onSignUpStart),
        call(onSignUpFailed),
        call(onSetFirstSurveyCompletedStart),
        call(onSetFirstSurveyCompletedSuccess),
        call(onSetLastSurveyCompletedStart),
        call(onSetLastSurveyCompletedSuccess),
        call(onRefreshUserProfile),
    ]);
}
