import { Fragment, lazy, Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    Navigate,
    Route,
    Routes,
    useLocation,
    useNavigate,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import {
    FAQ_ROUTE,
    HOME_ROUTE,
    LOGIN_ROUTE,
    NEWS_ROUTE,
    POLLUTION_ROUTE,
    SIGN_UP_ROUTE,
    USER_ROUTE,
} from './constants/routes.constants';
import './messaging_init_in_sw.js';
import Navigation from './routes/navigation/navigation.component';
import { selectAccessToken } from './store/auth/auth.selector';

const LoadedLoginPage = lazy(() => import('src/routes/login/login.component'));
const LoadedSignUpPage = lazy(
    () => import('src/routes/signup/signup.component')
);
const LoadedUserPage = lazy(() => import('src/routes/user/user.component'));
const LoadedHomePage = lazy(() => import('src/routes/home/home.component'));
const LoadedNewsPage = lazy(() => import('src/routes/news/news.component'));
const LoadedNewsDetailPage = lazy(
    () => import('src/routes/news/news-detail/news-detail.component')
);
const LoadedPollutionPage = lazy(
    () => import('src/routes/pollution/pollution.component')
);
const LoadedFaqPage = lazy(() => import('src/routes/faq/faq.component'));

function App() {
    const accessToken = useSelector(selectAccessToken);
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const isLoginPage = pathname === `/${LOGIN_ROUTE}`;
    const isSignUpPage = pathname === `/${SIGN_UP_ROUTE}`;

    useEffect(() => {
        if (!accessToken && !isLoginPage && !isSignUpPage) {
            navigate(`/${LOGIN_ROUTE}`);
        }
    }, [accessToken, isLoginPage, isSignUpPage, navigate]);

    return (
        <Fragment>
            <ToastContainer />
            <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                    <Route path="/" element={<Navigation />}>
                        <Route
                            path={LOGIN_ROUTE}
                            element={<LoadedLoginPage />}
                        />
                        <Route
                            path={SIGN_UP_ROUTE}
                            element={<LoadedSignUpPage />}
                        />
                        <Route path={USER_ROUTE} element={<LoadedUserPage />} />
                        <Route path={HOME_ROUTE} element={<LoadedHomePage />} />
                        <Route path={NEWS_ROUTE} element={<LoadedNewsPage />} />
                        <Route
                            path={`${NEWS_ROUTE}/:newsId/`}
                            element={<LoadedNewsDetailPage />}
                        />
                        <Route
                            path={`${POLLUTION_ROUTE}/:pollutantKey/`}
                            element={<LoadedPollutionPage />}
                        />
                        <Route
                            path={POLLUTION_ROUTE}
                            element={<LoadedPollutionPage />}
                        />
                        <Route path={FAQ_ROUTE} element={<LoadedFaqPage />} />
                        <Route path="" element={<Navigate to="/home" />} />
                        <Route path="*" element={<div>404</div>} />
                    </Route>
                </Routes>
            </Suspense>
        </Fragment>
    );
}

export default App;
