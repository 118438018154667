import { all, call } from 'typed-redux-saga/macro';
import { authSaga } from './auth/auth.saga';
import { faqSaga } from './faq/faq.saga';
import { newsSaga } from './news/news.saga';
import { pollutionSaga } from './pollution/pollution.saga';
import { settingsSaga } from './settings/settings.saga';

export function* rootSaga() {
    yield* all(
        [
            call(authSaga),
            call(settingsSaga),
            call(newsSaga),
            call(pollutionSaga),
            call(faqSaga),
        ]
    );
}
