import { useState } from 'react';
import {
    HeaderContainer,
    HeaderOuterBox,
    MainLogoSvg,
    UserLogoSvg,
} from './header.styles';

import { Popover } from 'react-tiny-popover';
import CustomPopoverMenu from 'src/components/ui/custom-popover-menu/custom-popover-menu.component';

const Header = () => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    return (
        <HeaderOuterBox>
            <HeaderContainer>
                <MainLogoSvg />
                <Popover
                    isOpen={isPopoverOpen}
                    positions={['left']} // preferred positions by priority
                    content={
                        <CustomPopoverMenu
                            onMenuItemClicked={() => setIsPopoverOpen(false)}
                        />
                    }
                    onClickOutside={() => setIsPopoverOpen(false)}
                >
                    <UserLogoSvg
                        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                    />
                </Popover>
            </HeaderContainer>
        </HeaderOuterBox>
    );
};

export default Header;
