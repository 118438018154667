import { useEffect, useState } from 'react';
import Layout from '../../components/layout/layout.component';

const Navigation = () => {
    const [landscape, setLandscape] = useState(
        window.screen.width > window.screen.height
    );

    useEffect(() => {
        const handleResizeChange = () => {
            console.log(window.screen.width, window.screen.height);
            setLandscape(window.screen.width > window.screen.height);
        };

        window.addEventListener('resize', handleResizeChange);

        return () => {
            window.removeEventListener('resize', handleResizeChange);
        };
    }, []);

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <div id="portrait" style={{ width: '100%', height: '100%' }}>
                <Layout />
            </div>
            {landscape && (
                <div id="landscape">Per favore ruota il dispositivo</div>
            )}
        </div>
    );
};

export default Navigation;
