import { Action } from '@reduxjs/toolkit';
import { getFAQFailed, getFAQSuccess } from './faq.action';

export type FAQ = {
    id: number;
    question: string;
    answer: string;
    published_at: string;
    archived: false;
};

export type FAQState = {
    readonly faq: FAQ[];
    readonly error: string | null;
};

const INITIAL_STATE: FAQState = {
    faq: [],
    error: null,
};

export const faqReducer = (state = INITIAL_STATE, action: Action) => {

    if (getFAQSuccess.match(action)) {
        return { ...state, faq: action.payload.faq, error: null };
    }

    if (
        getFAQFailed.match(action)
    ) {
        return { ...state, error: action.payload };
    }

    return state;
};