import mainBackground from 'src/assets/main-background.png';
import styled from 'styled-components';

export const PageContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    background-image: url(${mainBackground});
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
`;

export const PageContent = styled.div`
    height: 100%;
    width: 100%;
    padding: 0 2rem;
    overflow-y: auto;
    overflow-x: hidden;
    //background-color: lightgray;
`;

export const Title = styled.h2`
    width: 100%;
    color: ${(props) => props.theme.colors.primary.darker};
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin: 0.5rem;
    text-align: start;
`;

export const MainTitleSpacing = styled.div`
    height: 60px;
`;
