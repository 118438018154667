import { Action, ActionWithPayload, createAction, withMatcher } from '../../utils/reducer/reducer.utils';
import { FAQ } from './faq.reducer';
import { FAQ_ACTION_TYPES } from './faq.types';

export type GetFAQStart = Action<
    FAQ_ACTION_TYPES.GET_FAQ_START
>;

export type GetFAQSuccess = ActionWithPayload<
    FAQ_ACTION_TYPES.GET_FAQ_SUCCESS,
    { faq: FAQ[]; }
>;

export type GetFAQFailed = ActionWithPayload<
    FAQ_ACTION_TYPES.GET_FAQ_FAILED,
    string
>;

export type AddFAQStart = ActionWithPayload<
    FAQ_ACTION_TYPES.ADD_FAQ_START, { question: string; onComplete: (id: string) => void; }
>;

export type AddFAQSuccess = ActionWithPayload<
    FAQ_ACTION_TYPES.ADD_FAQ_SUCCESS,
    number
>;

export type AddFAQFailed = ActionWithPayload<
    FAQ_ACTION_TYPES.ADD_FAQ_FAILED,
    string
>;


export const getFAQStart = withMatcher(
    (): GetFAQStart =>
        createAction(FAQ_ACTION_TYPES.GET_FAQ_START)
);

export const getFAQSuccess = withMatcher(
    (faq: FAQ[]): GetFAQSuccess =>
        createAction(FAQ_ACTION_TYPES.GET_FAQ_SUCCESS, { faq })
);

export const getFAQFailed = withMatcher(
    (error: string): GetFAQFailed =>
        createAction(FAQ_ACTION_TYPES.GET_FAQ_FAILED, error)
);

export const addFAQStart = withMatcher(
    (question: string, onComplete: (id: string) => void): AddFAQStart =>
        createAction(FAQ_ACTION_TYPES.ADD_FAQ_START, { question, onComplete })
);

export const addFAQSuccess = withMatcher(
    (id: number): AddFAQSuccess =>
        createAction(FAQ_ACTION_TYPES.ADD_FAQ_SUCCESS, id)
);

export const addFAQFailed = withMatcher(
    (error: string): AddFAQFailed =>
        createAction(FAQ_ACTION_TYPES.ADD_FAQ_FAILED, error)
);
