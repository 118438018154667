import { ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';
import { THEME } from './theme';

export default function CustomThemeProvider({
    children,
}: {
    children: ReactNode;
}) {
    return <ThemeProvider theme={THEME}>{children}</ThemeProvider>;
}
