enum QUALIFICATION_CHOICES {
    PRIMARY_LOWER_MIDDLE_SCHOOL_CERTIFICATE = 'Licenza elem./media inf.',
    HIGH_SCHOOL_DIPLOMA = 'Diploma superiore',
    UNIVERSITY_DEGREE = 'Laurea',
}


enum PROVINCE_DOMICILE_CHOICES {
    AG = 'Agrigento', AL = 'Alessandria', AN = 'Ancona', AR = 'Arezzo', AP = 'Ascoli Piceno',
    AT = 'Asti',
    AV = 'Avellino', BA = 'Bari', BT = 'Barletta-Andria-Trani', BL = 'Belluno', BN = 'Benevento',
    BG = 'Bergamo', BI = 'Biella', BO = 'Bologna', BZ = 'Bolzano/Bozen', BS = 'Brescia',
    BR = 'Brindisi',
    CA = 'Cagliari', CL = 'Caltanissetta', CB = 'Campobasso', CI = 'Carbonia-Iglesias', CE = 'Caserta',
    CT = 'Catania', CZ = 'Catanzaro', CH = 'Chieti', CO = 'Como', CS = 'Cosenza', CR = 'Cremona',
    KR = 'Crotone', CN = 'Cuneo', EN = 'Enna', FM = 'Fermo', FE = 'Ferrara', FI = 'Firenze',
    FG = 'Foggia', FC = 'Forlì-Cesena', FR = 'Frosinone', GE = 'Genova', GO = 'Gorizia',
    GR = 'Grosseto',
    IM = 'Imperia', IS = 'Isernia', AQ = "L'Aquila", SP = 'La Spezia', LT = 'Latina', LE = 'Lecce',
    LC = 'Lecco', LI = 'Livorno', LO = 'Lodi', LU = 'Lucca', MC = 'Macerata', MN = 'Mantova',
    MS = 'Massa-Carrara', MT = 'Matera', VS = 'Medio Campidano', ME = 'Messina', MI = 'Milano',
    MO = 'Modena', MB = 'Monza e della Brianza', NA = 'Napoli', NO = 'Novara', NU = 'Nuoro',
    OG = 'Ogliastra', OT = 'Olbia-Tempio', OR = 'Oristano', PD = 'Padova', PA = 'Palermo',
    PR = 'Parma',
    PV = 'Pavia', PG = 'Perugia', PU = 'Pesaro e Urbino', PE = 'Pescara', PC = 'Piacenza',
    PI = 'Pisa',
    PT = 'Pistoia', PN = 'Pordenone', PZ = 'Potenza', PO = 'Prato', RG = 'Ragusa', RA = 'Ravenna',
    RC = 'Reggio di Calabria', RE = "Reggio nell'Emilia", RI = 'Rieti', RN = 'Rimini', RM = 'Roma',
    RO = 'Rovigo', SA = 'Salerno', SS = 'Sassari', SV = 'Savona', SI = 'Siena', SR = 'Siracusa',
    SO = 'Sondrio', TA = 'Taranto', TE = 'Teramo', TR = 'Terni', TO = 'Torino', TP = 'Trapani',
    TN = 'Trento', TV = 'Treviso', TS = 'Trieste', UD = 'Udine', AO = "Valle d'Aosta/Vallée d'Aoste",
    VA = 'Varese', VE = 'Venezia', VB = 'Verbano-Cusio-Ossola', VC = 'Vercelli', VR = 'Verona',
    VV = 'Vibo Valentia', VI = 'Vicenza', VT = 'Viterbo',
}

export const SICILIAN_PROVINCES = ['AG', 'CL', 'CT', 'EN', 'ME', 'PA', 'RG', 'SR', 'TP'];

enum AGE_RANGE_CHOICES {
    AGE_BT_18_22 = '18-22',
    AGE_BT_23_27 = '23-27',
    AGE_BT_28_32 = '28-32',
    AGE_BT_33_37 = '33-37',
    AGE_BT_38_42 = '38-42',
    AGE_GTE_43 = '43+',
}

export enum PREGNANCY_STAGE_CHOICES {
    PREGNANCY_FIRST_TRIMESTER = 'Primo trimestre gravidanza',
    PREGNANCY_SECOND_TRIMESTER = 'Secondo trimestre gravidanza',
    PREGNANCY_THIRD_TRIMESTER = 'Terzo trimestre gravidanza',
    NO_PREGNANCY = 'No gravidanza'
};

export const QUALIFICATION_CHOICES_PAIRS = Object.keys(QUALIFICATION_CHOICES).map(
    (key) => ({
        key,
        value: QUALIFICATION_CHOICES[
            key as keyof typeof QUALIFICATION_CHOICES
        ],
    })
);

export const PROVINCE_DOMICILE_CHOICES_PAIRS = Object.keys(
    PROVINCE_DOMICILE_CHOICES
).map((key) => ({
    key,
    value: PROVINCE_DOMICILE_CHOICES[
        key as keyof typeof PROVINCE_DOMICILE_CHOICES
    ],
}));

export const AGE_RANGE_CHOICES_PAIRS = Object.keys(AGE_RANGE_CHOICES).map(
    (key) => ({
        key,
        value: AGE_RANGE_CHOICES[key as keyof typeof AGE_RANGE_CHOICES],
    })
);