import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsSuccess } from 'src/store/success/success.action';
import {
    selectIsSuccess,
    selectSuccessMessage,
} from 'src/store/success/success.selector';
import {
    CustomSnackbarButton,
    SuccessSnackbarContainer,
} from './success-snackbar.styles';

const SuccessSnackbar = () => {
    const success = useSelector(selectIsSuccess);
    const successMessage = useSelector(selectSuccessMessage);

    const dispatch = useDispatch();

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(setIsSuccess(false, successMessage));
        }, 5000);

        return () => clearTimeout(timer);
    }, [dispatch, success, successMessage]);

    return (
        <Fragment>
            <SuccessSnackbarContainer show={success}>
                <p>{successMessage}</p>
                <CustomSnackbarButton
                    onClick={() => {
                        dispatch(setIsSuccess(false, successMessage));
                    }}
                >
                    X
                </CustomSnackbarButton>
            </SuccessSnackbarContainer>
        </Fragment>
    );
};

export default SuccessSnackbar;
