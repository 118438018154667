
import { NEWS_ACTION_TYPES } from './news.types';

import {
    Action,
    ActionWithPayload,
    createAction,
    withMatcher
} from '../../utils/reducer/reducer.utils';
import { News, NewsCategory, NewsFilters } from './news.reducer';

export type GetNewsStart = ActionWithPayload<
    NEWS_ACTION_TYPES.GET_NEWS_START, NewsFilters
>;

export type GetNewsSuccess = ActionWithPayload<
    NEWS_ACTION_TYPES.GET_NEWS_SUCCESS,
    { news: News[]; }
>;

export type GetNewsFailed = ActionWithPayload<
    NEWS_ACTION_TYPES.GET_NEWS_FAILED,
    string
>;

export type GetNewsCategoriesStart = Action<
    NEWS_ACTION_TYPES.GET_NEWS_CATEGORIES_START
>;

export type GetNewsCategoriesSuccess = ActionWithPayload<
    NEWS_ACTION_TYPES.GET_NEWS_CATEGORIES_SUCCESS,
    { newsCategories: NewsCategory[]; }
>;

export type GetNewsCategoriesFailed = ActionWithPayload<
    NEWS_ACTION_TYPES.GET_NEWS_CATEGORIES_FAILED,
    string
>;

export type ToggleNewsFavoriteStart = ActionWithPayload<
    NEWS_ACTION_TYPES.TOGGLE_NEWS_FAVORITE_START,
    { newsId: string; }
>;

export type ToggleNewsFavoriteSuccess = ActionWithPayload<
    NEWS_ACTION_TYPES.TOGGLE_NEWS_FAVORITE_SUCCESS,
    { accessToken: string; }
>;

export type ToggleNewsFavoriteFailed = ActionWithPayload<
    NEWS_ACTION_TYPES.TOGGLE_NEWS_FAVORITE_FAILED,
    string
>;


export const getNewsStart = withMatcher(
    (newsFilters: NewsFilters): GetNewsStart =>
        createAction(NEWS_ACTION_TYPES.GET_NEWS_START, newsFilters)
);

export const getNewsSuccess = withMatcher(
    (news: News[]): GetNewsSuccess =>
        createAction(NEWS_ACTION_TYPES.GET_NEWS_SUCCESS, { news })
);

export const getNewsFailed = withMatcher(
    (error: string): GetNewsFailed =>
        createAction(NEWS_ACTION_TYPES.GET_NEWS_FAILED, error)
);

export const getNewsCategoriesStart = withMatcher(
    (): GetNewsCategoriesStart =>
        createAction(NEWS_ACTION_TYPES.GET_NEWS_CATEGORIES_START)
);

export const getNewsCategoriesSuccess = withMatcher(
    (newsCategories: NewsCategory[]): GetNewsCategoriesSuccess =>
        createAction(NEWS_ACTION_TYPES.GET_NEWS_CATEGORIES_SUCCESS, { newsCategories })
);

export const getNewsCategoriesFailed = withMatcher(
    (error: string): GetNewsCategoriesFailed =>
        createAction(NEWS_ACTION_TYPES.GET_NEWS_CATEGORIES_FAILED, error)
);

export const toggleNewsFavoriteStart = withMatcher(
    (newsId: string): ToggleNewsFavoriteStart =>
        createAction(NEWS_ACTION_TYPES.TOGGLE_NEWS_FAVORITE_START, { newsId })
);

export const toggleNewsFavoriteSuccess = withMatcher(
    (accessToken: string): ToggleNewsFavoriteSuccess =>
        createAction(NEWS_ACTION_TYPES.TOGGLE_NEWS_FAVORITE_SUCCESS, { accessToken })
);

export const toggleNewsFavoriteFailed = withMatcher(
    (error: string): ToggleNewsFavoriteFailed =>
        createAction(NEWS_ACTION_TYPES.TOGGLE_NEWS_FAVORITE_FAILED, error)
);
