import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsError } from 'src/store/error/error.action';
import {
    selectErrorMessage,
    selectIsError,
} from 'src/store/error/error.selector';
import {
    CustomSnackbarButton,
    CustomSnackbarContainer,
} from './custom-snackbar.styles';

const CustomSnackbar = () => {
    const error = useSelector(selectIsError);
    const errorMessage = useSelector(selectErrorMessage);

    const dispatch = useDispatch();

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(setIsError(false, errorMessage));
        }, 5000);

        return () => clearTimeout(timer);
    }, [dispatch, error, errorMessage]);

    return (
        <Fragment>
            <CustomSnackbarContainer show={error}>
                <p>{errorMessage}</p>
                <CustomSnackbarButton
                    onClick={() => {
                        dispatch(setIsError(false, errorMessage));
                    }}
                >
                    X
                </CustomSnackbarButton>
            </CustomSnackbarContainer>
        </Fragment>
    );
};

export default CustomSnackbar;
