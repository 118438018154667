import { Action as AnyAction } from '@reduxjs/toolkit';
import { getNewsCategoriesSuccess, getNewsFailed, getNewsSuccess } from './news.action';

export type NewsFilters = {
    searchText: string;
    categories: number[];
    favorite: boolean;
};

export type NewsCategory = {
    id: number;
    name: string;
    image: string;
};

export type News = {
    id: number;
    title: string;
    image: string;
    published_at: string;
    categories: number[];
    content: string;
    audio_file: string | null;
    youtube_video_id: string | null;
    external_link_label: string | null;
    external_link_url: string | null;
};

export type NewsState = {
    readonly news: News[];
    readonly newsCategories: NewsCategory[];
    readonly isLoading: boolean;
    readonly error: string | null;
};

const INITIAL_STATE: NewsState = {
    news: [],
    newsCategories: [],
    isLoading: false,
    error: null,
};

export const newsReducer = (state = INITIAL_STATE, action: AnyAction) => {
    if (getNewsSuccess.match(action) || getNewsCategoriesSuccess.match(action)) {
        return { ...state, ...action.payload, error: null };
    }

    if (
        getNewsFailed.match(action)
    ) {
        return { ...state, error: action.payload };
    }

    return state;
};