import { MenuItem } from '../menu.styles';

const MenuHome = ({ fill }: { fill: string }) => {
    return (
        <MenuItem
            width="52"
            height="50"
            viewBox="0 0 52 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M29.2552 2.74287C28.8055 2.29332 28.1957 2.04077 27.5598 2.04077C26.924 2.04077 26.3142 2.29332 25.8645 2.74287L9.07877 19.5288L4.28285 24.3247C4.05382 24.5459 3.87114 24.8105 3.74546 25.1031C3.61979 25.3957 3.55364 25.7103 3.55087 26.0287C3.5481 26.3471 3.60878 26.6629 3.72935 26.9576C3.84992 27.2523 4.02798 27.5201 4.25313 27.7452C4.47828 27.9704 4.74601 28.1484 5.04071 28.269C5.33542 28.3896 5.65118 28.4502 5.96958 28.4475C6.28798 28.4447 6.60264 28.3786 6.8952 28.2529C7.18776 28.1272 7.45236 27.9445 7.67356 27.7155L8.37617 27.0129V42.806C8.37617 44.714 9.13409 46.5438 10.4832 47.8929C11.8323 49.242 13.6621 50 15.57 50H39.5496C41.4576 50 43.2874 49.242 44.6365 47.8929C45.9856 46.5438 46.7435 44.714 46.7435 42.806V27.0129L47.4461 27.7155C47.8984 28.1523 48.5041 28.394 49.1328 28.3885C49.7616 28.3831 50.363 28.1309 50.8076 27.6863C51.2522 27.2417 51.5044 26.6402 51.5099 26.0115C51.5153 25.3827 51.2736 24.777 50.8368 24.3247L29.2552 2.74287Z"
                fill={fill}
            />
            <path
                d="M1.56917 22.6436L1.56922 22.6437L1.57537 22.6375L6.37129 17.8415L23.1569 1.0557C23.157 1.05568 23.157 1.05567 23.157 1.05565C23.5129 0.699865 23.9956 0.5 24.4988 0.5C25.002 0.5 25.4847 0.699865 25.8406 1.05565C25.8406 1.05567 25.8406 1.05568 25.8407 1.0557L47.419 22.6343C47.7629 22.9919 47.9532 23.4701 47.9489 23.9664C47.9445 24.464 47.7449 24.9401 47.393 25.2919C47.0411 25.6439 46.5651 25.8435 46.0675 25.8478C45.5712 25.8521 45.093 25.6618 44.7353 25.3179L44.036 24.6185L43.1825 23.765V24.9721V40.7652C43.1825 42.5406 42.4772 44.2432 41.2219 45.4986C39.9665 46.7539 38.2639 47.4592 36.4886 47.4592H12.509C10.7337 47.4592 9.03107 46.7539 7.77572 45.4986C6.52038 44.2432 5.81513 42.5406 5.81513 40.7652V24.9721V23.765L4.96157 24.6185L4.25897 25.3212L4.25892 25.3211L4.25288 25.3274C4.0778 25.5086 3.86837 25.6532 3.63682 25.7527C3.40526 25.8522 3.15621 25.9045 2.9042 25.9067C2.65219 25.9089 2.40227 25.8609 2.16901 25.7655C1.93576 25.67 1.72385 25.5291 1.54565 25.3509L1.19209 25.7044L1.54565 25.3509C1.36744 25.1727 1.22651 24.9608 1.13108 24.7275C1.03565 24.4942 0.987628 24.2443 0.989818 23.9923C0.992008 23.7403 1.04437 23.4912 1.14384 23.2597C1.24331 23.0281 1.3879 22.8187 1.56917 22.6436Z"
                stroke="black"
            />
        </MenuItem>
    );
};

export default MenuHome;
