import { Action, ActionWithPayload, createAction, withMatcher } from '../../utils/reducer/reducer.utils';
import { Settings } from './settings.reducer';
import { SETTINGS_ACTION_TYPES } from './settings.types';

export type GetSettingsStart = Action<
    SETTINGS_ACTION_TYPES.GET_SETTINGS_START
>;

export type GetSettingsSuccess = ActionWithPayload<
    SETTINGS_ACTION_TYPES.GET_SETTINGS_SUCCESS,
    { settings: Settings[]; }
>;

export type GetSettingsFailed = ActionWithPayload<
    SETTINGS_ACTION_TYPES.GET_SETTINGS_FAILED,
    string
>;

export const getSettingsStart = withMatcher(
    (): GetSettingsStart =>
        createAction(SETTINGS_ACTION_TYPES.GET_SETTINGS_START)
);

export const getSettingsSuccess = withMatcher(
    (settings: Settings[]): GetSettingsSuccess =>
        createAction(SETTINGS_ACTION_TYPES.GET_SETTINGS_SUCCESS, { settings })
);

export const getSettingsFailed = withMatcher(
    (error: string): GetSettingsFailed =>
        createAction(SETTINGS_ACTION_TYPES.GET_SETTINGS_FAILED, error)
);