import { MenuItem } from '../menu.styles';

const MenuNews = ({ fill }: { fill: string }) => {
    return (
        <MenuItem
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.24515 50C5.95433 50 4.8497 49.4786 3.93127 48.4359C3.01283 47.3933 2.55283 46.1383 2.55127 44.6712V2.04077L6.48239 6.50364L10.3548 2.04077L14.286 6.50364L18.2171 2.04077L22.0895 6.50364L26.0207 2.04077L29.9518 6.50364L33.8242 2.04077L37.7553 6.50364L41.6865 2.04077L45.5589 6.50364L49.49 2.04077V44.6712C49.49 46.1366 49.0308 47.3915 48.1124 48.4359C47.194 49.4804 46.0885 50.0017 44.7962 50H7.24515ZM7.24515 44.6712H23.6737V28.6848H7.24515V44.6712ZM28.3676 44.6712H44.7962V39.3424H28.3676V44.6712ZM28.3676 34.0136H44.7962V28.6848H28.3676V34.0136ZM7.24515 23.356H44.7962V15.3628H7.24515V23.356Z"
                fill={fill}
            />
            <path
                d="M1.01025 42.6301V1.32408L4.06618 4.79336L4.44421 5.22253L4.81903 4.79056L8.31665 0.759657L11.8697 4.79336L12.2449 5.21931L12.6201 4.79336L16.1732 0.759657L19.6709 4.79056L20.0457 5.22253L20.4237 4.79336L23.9796 0.756444L27.5356 4.79336L27.9136 5.22253L28.2884 4.79056L31.786 0.759657L35.3391 4.79336L35.7143 5.21931L36.0895 4.79336L39.6426 0.759657L43.1403 4.79056L43.5151 5.22253L43.8931 4.79336L46.949 1.32408V42.6304C46.949 43.9814 46.5295 45.117 45.6959 46.065C44.8644 47.0106 43.8923 47.4607 42.7558 47.4592H42.7551H5.20413C4.06886 47.4592 3.09716 47.0089 2.26545 46.0647C1.43214 45.1187 1.01175 43.9831 1.01025 42.6301ZM4.70413 42.6304V43.1304H5.20413H21.6327H22.1327V42.6304V26.644V26.144H21.6327H5.20413H4.70413V26.644V42.6304ZM25.8266 42.6304V43.1304H26.3266H42.7551H43.2551V42.6304V37.3016V36.8016H42.7551H26.3266H25.8266V37.3016V42.6304ZM25.8266 31.9728V32.4728H26.3266H42.7551H43.2551V31.9728V26.644V26.144H42.7551H26.3266H25.8266V26.644V31.9728ZM4.70413 21.3152V21.8152H5.20413H42.7551H43.2551V21.3152V13.322V12.822H42.7551H5.20413H4.70413V13.322V21.3152Z"
                stroke="black"
            />
        </MenuItem>
    );
};

export default MenuNews;
