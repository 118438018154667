import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { USER_ROUTE } from 'src/constants/routes.constants';
import { signOut } from 'src/store/auth/auth.action';
import {
    PopoverContainer,
    PopoverMenu,
    PopoverMenuItem,
} from './custom-popover-menu.styles';

const CustomPopoverMenu = ({
    onMenuItemClicked,
}: {
    onMenuItemClicked: () => void;
}) => {
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const doLogout = async () => {
        onMenuItemClicked();
        await dispatch(signOut());
    };

    const openUserProfile = () => {
        onMenuItemClicked();
        navigate(USER_ROUTE);
    };

    return (
        <PopoverContainer>
            <PopoverMenu>
                <PopoverMenuItem onClick={openUserProfile}>
                    Profilo utente
                </PopoverMenuItem>
                <PopoverMenuItem onClick={doLogout}>Logout</PopoverMenuItem>
            </PopoverMenu>
        </PopoverContainer>
    );
};

export default CustomPopoverMenu;
