import { AnyAction } from '@reduxjs/toolkit';
import { getSettingsFailed, getSettingsSuccess } from './settings.action';

export type Settings = {
    id: number;
    key: string;
    value: string;
};

export type SettingsState = {
    readonly settings: Settings[];
    readonly error: string | null;
};

const INITIAL_STATE: SettingsState = {
    settings: [],
    error: null,
};

export const settingsReducer = (state = INITIAL_STATE, action: AnyAction) => {

    if (getSettingsSuccess.match(action)) {
        return { ...state, settings: action.payload.settings, error: null };
    }

    if (
        getSettingsFailed.match(action)
    ) {
        return { ...state, error: action.payload };
    }

    return state;
};