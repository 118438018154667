import {
    ActionWithPayload, createAction,
    withMatcher
} from '../../utils/reducer/reducer.utils';
import { SUCCESS_ACTION_TYPES } from './success.types';

export type SetIsSuccess = ActionWithPayload<
    SUCCESS_ACTION_TYPES.SET_IS_SUCCESS,
    { isSuccess: boolean; successMessage: string | null; }
>;

export const setIsSuccess = withMatcher(
    (isSuccess: boolean, successMessage: string | null): SetIsSuccess =>
        createAction(SUCCESS_ACTION_TYPES.SET_IS_SUCCESS, { isSuccess, successMessage })
);